.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper .swiper-pagination {
  display: none;
}

.custom-slide {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #4770FF;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}

@media only screen and (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 1400px) {
  .wrapper {
    max-width: 1320px;
  }
}